<template>
  <div class="case-study">
    <h2 class="header">3D STORE CASE STUDY</h2>
    
    <div class="laptop">
      <div class="mockup mockup-macbook loaded opened">
        <div class="part top">
          <div class="screen-panel-wrap">
            <div class="panel">
                <iframe src="https://in3d-website.onrender.com/" frameborder="0"></iframe>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="rounded-area"></div>
          <div class="indent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "CaseStudy",
  components: {},
  data: () => {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// iframe {
//   width: 500px;
//   height: 500px;
// }
.header {
 font-size: 4rem;
}

.laptop {
  width: 70%;
  &:hover {
    .cover {
      // opacity: 0;
    }
  }
}


</style>
