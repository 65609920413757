<template>
  <div class="virtual-assistant">
    <img src="img/main-page/holo-mech.webp" alt="" class="bg" />
    <div class="inner-wrap">
      <h2>Virtual Assitant</h2>
      <div class="bottom-wrap">
        <h3>ALWAYS BY YOUR SIDE...</h3>
        <p>
          Using XR headset or any other device – smartphone, tablet or a
          computer you can easily have your employee in the field connected,
          assisting him, analyzing with him, leading him step by step from your
          office PC, you can monitor, assist and advice as you were in the room
          with him. With XR tech you can turn video calls to holographic calls,
          enabling both sides to make AR markings, demonstrate actions and
          supervise the work process.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "VirtualAssitant",
  components: {},
  data: () => {
    return {};
  },
  mounted() {},
  scrollTest: function () {
    console.log("work?");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.virtual-assistant {
  &:hover {
    .bg {
      opacity: 1;
    }
  }
}
.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: 2s;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}
.inner-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
h2 {
  font-size: 6rem;
}
.bottom-wrap {
  margin: 100px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h3 {
    font-size: 4rem;
    text-align: right;
  }
  p {
    font-size: 3rem;
  }
}
</style>
