<template>
  <div class="simulate-stimulate">
    <div class="wrap">
      <h2>Simulate & Stimulate</h2>
      <div class="heart-wrap">
        <p>
          The future is now. Using XR (extended reality: VR/AR/MR) devices, we
          can create the ultimate simulators in a variety of uses and fields.
          Through XR tech we can simulate anything with unlimited options and
          abilities.
        </p>
        <img src="img/main-page/heart.gif" alt="" />
      </div>
      <!-- <img src="/img/engine.gif" alt="" /> -->
    </div>
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "SimulateStimulate",
  components: {},
  data: () => {
    return {};
  },
  mounted() {},
  scrollTest: function () {
    console.log("work?");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$black: #0a0a0a;
$gray: #212121;
$lightGray: #bfbfbf;
$background: #1f1b24;

$primary: #bb86fc;
$primaryTwo: #3700b3;
$secondary: #03dac6;
$error: #cf6679;

.simulate-stimulate {
  text-align: left;
      display: flex;
    align-items: center;
}
.wrap {
  margin: 0 15%;
  .heart-wrap {
    display: flex;
    align-items: center;
    p {
      font-size: 2rem;
    }
  }
  h2 {
    font-size: 72px;
    background-image: linear-gradient(to top, #505285 0%, #585e92 12%, #65689f 25%, #7474b0 37%, #7e7ebb 50%, #8389c7 62%, #9795d4 75%, #a2a1dc 87%, #b5aee4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

</style>
