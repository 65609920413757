<template>
  <div class="digital-twin">
    <h2>“ IF A PICTURE IS WORTH a 1000 WORDS</h2>
    <h2>THAN THE WORTH OF A 3D MODEL IS... ”</h2>
    <div class="hands">
      <img class="hand1" src="img/main-page/human-hand.png" alt="" />
      <img class="hand2" src="img/main-page/robo-hand.png" alt="" />
    </div>
    <div class="typed-wrap">
      <h3 class="typed-text">
        <span class="typed1"></span><span class="typed1"></span>
      </h3>
    </div>
    <p>
      Creating a 3D digital twin changes the entire approach of how we observe,
      measure, learn, calculate and develop our product. It doesn't matter if
      your product or space is a merchant ship or a microchip, with a digital
      twin you can easily dive into a digital universe that enables an absolute
      control with endless utilities.
    </p>
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "DigitalTwin",
  components: {},
  data: () => {
    return {
      textForTyping: "DIGITAL TWIN",
      typedText: "",
      moveProgress: 0,
    };
  },
  mounted() {
    var options1 = {
      strings: ["DIGITAL", "TWIN"],
      typeSpeed: 200,
      loop: true,
    };

    var typed = new Typed(".typed1", options1);
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$black: #0a0a0a;
$gray: #212121;
$lightGray: #bfbfbf;
$background: #1f1b24;

$primary: #bb86fc;
$primaryTwo: #3700b3;
$secondary: #03dac6;
$error: #cf6679;

.typed-wrap {
  display: flex;
  justify-content: center;
}

.digital-twin {
  flex-direction: column;
  font-family: "DM Serif Display", serif;
  h2 {
    margin: 0;
    font-size: 2.6rem;
  }
  h3 {
    display: inline;
    margin: 0;
    font-size: 3rem;
    font-family: "Courier New", monospace;
    .twin {
      text-shadow: 5px 5px 2px $error;
    }
    .character {
      opacity: 0;
    }
    .digit {
      margin: -13px;
    }
    .space {
      margin: 12px;
    }
  }
  .hands {
    width: 100%;
    display: flex;
    img {
      width: 100%;
      transition: 1s;
    }
    .hand1 {
      transform: translateX(-100%);
    }
    .hand2 {
      transform: translateX(100%);
    }
  }
  &:hover {
    .hand1,
    .hand2 {
      transform: unset;
    }
  }
}
</style>
