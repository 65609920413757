<template>
  <div class="home">
    <div
      id="mouse-circle"
      :class="`mouse-circle ${mouseHover ? 'hovering' : ''} ${
        blackCircle ? 'black-circle' : ''
      }`"
      ref="cursor"
    ></div>
    <!-- <router-link to="/about">About</router-link> -->
    <!-- <router-link
      to="/"
      class="logo"
      @mouseenter="cursorSize('enter')"
      @mouseleave="cursorSize('leave')"
    >
      <span :class="`square ${mouseHover ? 'hovering' : ''}`"></span
      ><span class="innerTxt">3D<span class="subInnerTxt">IN</span></span>
      <span class="mainTxt">Simply Expand</span>
    </router-link> -->
    <!-- <div :class="`bk-img ${menuOpen ? 'open' : 'closed'}`"></div> -->
    <!-- {{ moveProgress }} -->

    <Scene
      :menuOpen="menuOpen"
      :scrollAmount="scrollAmount"
      :scene3CardsHover="scene3CardsHover"
      :elementSwitch="elementSwitch"
    />

    <div class="main-content">
      <section class="word-transitions" style="opacity: 0.99">
        <span v-for="(word, i) in enterWords" :key="i">
          <p
            v-if="moveProgress > i * 20 || i === 0"
            @mouseenter="cursorSize('enter')"
            @mouseleave="cursorSize('leave')"
            :class="`fade-word animate__animated animate__zoomIn ${
              mouseHover ? 'hovering' : ''
            } frame${i + 1 / 2}`"
          >
            {{ word }}
          </p>
        </span>
      </section>
      <section class="info">
        <div
          :class="`earth-text ${
            scrollAmount > elementSwitch + 10 ? 'active' : ''
          }`"
        >
          <h2>We Are <span class="threed">3D</span></h2>
          <h2>Professionals</h2>
        </div>
      </section>
      <section class="info">
        <div class="we-are">
          <p>WE ARE 3D PROFESSIONALS AND THE ONLY ONE STOP SHOP FOR:</p>
          <ul>
            <li @mouseenter="scene3CardsHover.scene1 = true">
              3D software, applications and web development
            </li>
            <li @mouseenter="scene3CardsHover.scene2 = true">
              3D scanning and photographing
            </li>
            <li @mouseenter="scene3CardsHover.scene3 = true">
              Simulating and animating
            </li>
          </ul>
        </div>
      </section>
      <section class="info startup">
        <div class="hover-direction top-left"></div>
        <div class="hover-direction top-right"></div>
        <div class="hover-direction bottom-left"></div>
        <div class="hover-direction bottom-right"></div>
        <div class="not">
          <p class="grad">WE ARE NOT A STARTUP</p>
          <div class="wait-wrap">
            <p class="grad wait">WE RATHER START WORKING WITH YOU</p>

            <p class="inner-wait">
              WE RATHER <span class="shadow">START</span> WORKING WITH YOU
            </p>
          </div>
        </div>
      </section>
      <section class="info laptop-wrap">
        <div class="laptop">
          <div class="mockup mockup-macbook loaded opened">
            <div class="part top">
              <div class="top-cover top"></div>
              <div class="screen-panel-wrap">
                <div class="panel">
                  <video autoplay muted loop>
                    <source src="video/in3d_vid.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="rounded-area"></div>
              <div class="indent"></div>
            </div>
          </div>
        </div>
        <p class="text-wrap">
          Despite our name, In3D is in more than just 3, we are here to help
          you, your employees and your clients in various fields, such as
          training, simulations, engineering, marketing, operating and more,
          <a href="/about">contact us</a> to go 3D
        </p>
      </section>
      <section class="info digital-twin-wrap">
        <DigitalTwin />
      </section>
      <section class="info extended-reality-wrap">
        <ExtendedReality class="reality-component" />
      </section>
      <section class="info simulate-stimulate-wrap">
        <SimulateStimulate />
      </section>
      <section class="info taylor-made-wrap">
        <TaylorMade />
      </section>
      <section class="info use-case-wrap">
        <UseCase />
      </section>
      <section class="info virtual-assistant-wrap">
        <VirtualAssistant />
      </section>
      <section class="info digitize-wrap">
        <Digitize />
      </section>
      <section class="info case-study-wrap">
        <CaseStudy />
      </section>
      <section class="info realization-wrap">
        <Realization />
      </section>
      <section class="info animations-wrap">
        <Animations />
      </section>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";

import Scene from "@/components/home/Scene.vue";
import Menu from "@/components/Menu.vue";
import DigitalTwin from "@/components/home/DigitalTwin.vue";
import ExtendedReality from "@/components/home/ExtendedReality.vue";
import SimulateStimulate from "@/components/home/SimulateStimulate.vue";
import TaylorMade from "@/components/home/TaylorMade.vue";
import UseCase from "@/components/home/UseCase.vue";
import VirtualAssistant from "@/components/home/VirtualAssistant.vue";
import Digitize from "@/components/home/Digitize.vue";
import CaseStudy from "@/components/home/CaseStudy.vue";
import Realization from "@/components/home/Realization.vue";
import Animations from "@/components/home/Animations.vue";
export default {
  name: "HelloWorld",
  components: {
    Scene,
    Menu,
    DigitalTwin,
    ExtendedReality,
    SimulateStimulate,
    TaylorMade,
    UseCase,
    VirtualAssistant,
    Digitize,
    CaseStudy,
    Realization,
    Animations,
    Nav,
  },
  data: () => {
    return {
      moveProgress: 0,
      frames: [],
      frame: 1,
      scrollAmount: 0,
      elementSwitch: 0,
      mouseHover: false,
      blackCircle: false,
      menuOpen: false,
      scene3CardsHover: { scene1: false, scene2: false, scene3: false },
      enterWords: [
        "We are on a mission to evolve",
        "Create and inspire",
        "3D isn't only a technology",
        "It's a way of thinking",
        "Our goal is the gather all senses",
        "Capturing is no longer an action of the eyes alone",
      ],
    };
  },
  mounted() {
    this.frames = document.querySelectorAll(".fade-word");

    // let mousePosX = 0,
    //   mousePosY = 0,
    //   mouseCircle = document.getElementById("mouse-circle");

    // document.onmousemove = (e) => {
    //   this.moveProgress++;
    //   mousePosX = e.pageX;
    //   mousePosY = e.pageY;
    // };

    // let delay = 3,
    //   revisedMousePosX = 0,
    //   revisedMousePosY = 0;

    // function delayMouseFollow() {
    //   requestAnimationFrame(delayMouseFollow);

    //   revisedMousePosX += (mousePosX - revisedMousePosX) / delay;
    //   revisedMousePosY += (mousePosY - revisedMousePosY) / delay;

    //   mouseCircle.style.top = revisedMousePosY + "px";
    //   mouseCircle.style.left = revisedMousePosX + "px";
    // }
    // delayMouseFollow();

    let elementSwitch = (document.querySelector(".info").scrollHeight / 3) * 2;
    this.elementSwitch = elementSwitch;
    let htmlEl = document.querySelector("html");
    document.addEventListener("scroll", (e) => {
      let scrollAmount = htmlEl.scrollTop;
      this.scrollAmount = scrollAmount;
    });
  },
  methods: {
    test: function () {
      console.log("work?");
    },
    cursorSize: function () {
      this.mouseHover = !this.mouseHover;
    },
    cursorBlack: function () {
      this.blackCircle = !this.blackCircle;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$black: #0a0a0a;
$gray: #212121;
$lightGray: #bfbfbf;
$background: #1f1b24;

$primary: #bb86fc;
$primaryTwo: #3700b3;
$secondary: #03dac6;
$error: #cf6679;

.home {
  overflow: hidden;
}
.main-content {
  z-index: 3;
  height: 100%;
}
section {
  height: 100vh;
}

.word-transitions {
  text-align: left;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  p {
    display: inline-block;
    font-size: 3rem;
    margin: 13px;
    text-shadow: 2px 4px 2px blue, -2px 4px 2px $error;
    transition: 1s;
    &:hover {
      text-shadow: 2px 5px 20px $error, -2px 5px 20px blue;
    }
  }
}
.earth-text {
  font-size: 4rem;
  z-index: -1;
  position: relative;
  right: 0%;
  transition: 4s;

  &.active {
    right: 10%;
  }
  h2 {
    margin: 2px;
  }
}
.we-are {
  position: relative;
  // right: 10%;
  font-size: 30px;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      z-index: 2;
      position: relative;
      &::before {
        z-index: -1;
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity 2s ease-in-out;
        opacity: 0;
      }
      &:nth-child(1) {
        background: linear-gradient(to top, #4c102a 0%, #151516 100%);
      }
      &:nth-child(2) {
        background: linear-gradient(to top, #4c102a 40%, #151516 100%);
      }
      &:nth-child(3) {
        background: linear-gradient(to top, #4c102a 80%, #151516 100%);
      }
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 40px;
      max-width: 17%;
      height: 30vh;
      margin-right: 20px;
      border-radius: 15px;
    }
    &:hover {
      li {
        &::before {
          transition: opacity 2s ease-in-out;
          opacity: 1;
        }
        &:nth-child(1)::before {
          background: linear-gradient(to top, #4c102a 80%, #151516 100%);
        }
        &:nth-child(3)::before {
          background: linear-gradient(to top, #4c102a 0%, #151516 100%);
        }
      }
    }
  }
}

.not {
  font-size: 4em;
  text-align: left;
  .grad {
    background: radial-gradient(#eff1ff, #000);
    background-size: 100% 200%;
    background-position: 50% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 16px;
    padding-bottom: 12px;
  }
  .wait-wrap {
    position: relative;

    .inner-wait {
      transition: 3s;
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      z-index: 9;
      margin: 0;
      opacity: 0;
    }
  }
}
.startup {
  position: relative;
  display: grid !important;

  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  &:hover .inner-wait {
    opacity: 1;
  }
  .hover-direction {
    // border: 1px solid white;
    height: 100%;
    z-index: 3;
    .shadow {
      opacity: 0;
      transition: 2s;
    }
    &.top-left:hover {
      & ~ .not {
        .shadow {
          opacity: 1;
          text-shadow: -5px -5px 4px white;
        }
      }
    }
    &.top-right:hover {
      & ~ .not {
        .shadow {
          opacity: 1;

          text-shadow: 5px -5px 4px white;
        }
      }
    }
    &.bottom-left:hover {
      & ~ .not {
        .shadow {
          opacity: 1;

          text-shadow: -5px 5px 4px white;
        }
      }
    }
    &.bottom-right:hover {
      & ~ .not {
        .shadow {
          opacity: 1;
          text-shadow: 5px 5px 4px white;
        }
      }
    }
  }
  .not {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  // &:hover {

  //   .inner-wait {
  //     opacity: 1;
  //     text-shadow: 1px 5px 10px white;
  //     z-index: 23;
  //   }
  // }
}
.threed {
  text-shadow: 1px 2px $error, 2px 3px $error, 3px 4px $error, 4px 5px $error,
    5px 6px $error, 6px 7px $error;
}
.info {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    text-align: left;
  }

  .text-wrap {
    font-size: 1.7rem;
    padding: 40px;
  }
}


.laptop-wrap {
  display: flex;
  flex-direction: column;
}
.laptop {
  width: 70%;
  &:hover {
    .cover {
      // opacity: 0;
    }
  }
}

.top-cover {
  background-image: linear-gradient(#48494d, #76777c);
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 1;
  height: 10px;
  width: 100%;
  border-radius: 50% 50% 0 0;
}
.screen-panel-wrap {
  width: 694px;
  height: 426px;
  background-image: linear-gradient(#48494d, #76777c);
  border-radius: 15px 10px;
  // instead of original img styling
  position: relative;
  display: block;
  max-width: 100%;
  backface-visibility: hidden;
  .panel {
    overflow: hidden;
    // content: "";
    border-radius: 15px 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: black;
    width: 99%;
    height: 99%;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background: #101010;
      width: 100%;
      height: 22px;
    }
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 92%;
      box-shadow: 0 0 20px white;
      backface-visibility: hidden;
    }
  }
}
.bottom {
  position: absolute;
  left: -10.7%;
  width: 121.4%;
  height: 9px;
  background: #75767a;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5%;
    height: 100%;
    background-image: linear-gradient(to right, #48494d, #76777c);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 5%;
    height: 100%;
    background-image: linear-gradient(to left, #48494d, #76777c);
  }
  .rounded-area {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 11px;
    background-image: linear-gradient(#48494d, #76777c);
    border-radius: 0% 100% 50% 50% / 0% 0% 100% 100%;
    // transform: rotateX(-20deg);
  }
  .indent {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 20%;
    background: #75767a;
    height: 11px;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 10%;
      height: 100%;
      background-image: linear-gradient(to right, #48494d, #76777c);
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 10%;
      height: 100%;
      background-image: linear-gradient(to left, #48494d, #76777c);
    }
  }
}

.mockup {
  display: inline-block;
  position: relative;
  z-index: 3;
  text-align: center;
  font-size: 0;
  perspective: 2400px;
  perspective-origin: 50% 100%;
  opacity: 0;
  transition: 500ms opacity;
}

.mockup.loaded {
  opacity: 1;
}

.top {
  position: absolute;
  top: 0;
  left: 0;
}

.top {
  transform: translate3d(0, 0, 0) rotateX(-90deg);
}

.mockup:hover .top {
  transform: translate3d(0, 0, 0) rotateX(0deg);
}

.mockup .part {
  display: inline-block;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform-origin: 50% 100%;
  transition: 900ms;
}

.mockup.opened .part .top {
  transform: translate3d(0, 0, -11px) rotateX(90deg) scale(1, 1);
}

.mockup .part .top {
  transform-origin: 50% 0;
  transform: translate3d(0, 0, -11px) rotateX(90deg);
  transition: 900ms;
}

// .extended-reality-wrap {
//   .reality-component {
//     display: none;
//   }
//   &:hover {
//     .reality-component {
//       display: block;
//     }
//   }
// }
</style>
