<template>
  <section
    class="menu"
    :style="`background: black; background-img: url(${itemHoverImg})`"
  >
    <ul>
      <li
        v-for="(item, index) in menuItems"
        :key="index"
        :class="mouseHover ? 'hovering' : ''"
        @mouseenter="$emit('enter'), menuItemHover(true, item.link, index)"
        @mouseleave="$emit('leave'), menuItemHover(false, item.link, index)"
        class="menu-item"
      >
        <router-link :to="'/' + item.routeName">{{ item.name }}</router-link>
        <span class="rounded-line"></span>
      </li>
    </ul>
    <div class="footer">
      <button class="touch">Get in touch</button>
      <div class="links">
        <span
          class="links-wrap"
          v-for="(item, index) in socialItems"
          :key="index"
        >
          <a
            class="social"
            :href="item.link"
            target="_blank"
            v-if="item.logo !== 'insta'"
            @mouseover="hoverSocial(true, index)"
            @mouseleave="hoverSocial(false, index)"
          >
            {{ item.logo }}
          </a>
          <a
            class="social instagram"
            :href="item.link"
            target="_blank"
            v-else
            @mouseover="hoverSocial(true, index)"
            @mouseleave="hoverSocial(false, index)"
          >
            <span class="outer"
              ><span class="inner"><span class="dot"></span></span
            ></span>
          </a>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Menu",
  props: ["mouseHover"],
  data: () => {
    return {
      itemHoverImg: "",
      menuItems: [
        { routeName: "about", name: "WHO WE ARE" },
        // { routeName: "", name: "VIRTUAL ASSISTANT", link: "/img/va-bk.jpg" },
        { routeName: "XR", name: "XR", link: "/img/xr-bk.jpg" },
        {
          routeName: "digital-world",
          name: "DIGITAL WORLD",
          link: "/img/xr-bk.jpg",
        },
        {
          routeName: "digital-twin",
          name: "DIGITAL TWIN",
          link: "/img/dw-bk.webp",
        },
        {
          routeName: "animation",
          name: "ANIMATION",
          link: "/img/anim-bk.webp",
        },
        { routeName: "3d-scanning", name: "3D SCANNING", link: "/img/scan-bk.webp" },
      ],
      socialItems: [
        { logo: "f", link: "https://www.facebook.com/in3d.tech/" },
        { logo: "in", link: "https://www.linkedin.com/company/in3d-tech.com" },
        { logo: "insta", link: "https://www.instagram.com/in3d_tech/" },
      ],
    };
  },
  mounted() {},
  methods: {
    hoverSocial: function (enter, indexHovering) {
      let icons = document.querySelectorAll(".social");
      for (let i = 0; i < icons.length; i++) {
        const icon = icons[i];
        if (!enter) icon.style.opacity = "1";
        else if (i !== indexHovering) icon.style.opacity = "0.2";
      }
    },
    menuItemHover: function (enter, link, index) {
      let item = document.querySelector(".menu");
      console.log(item);
      if (enter) {
        this.itemHoverImg = link;
        item.classList.toggle("img-effect");
      } else {
        item.classList.toggle("img-effect");
        this.itemHoverImg = "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap");

$black: #0a0a0a;
$gray: #212121;
$lightGray: #bfbfbf;
$background: #1f1b24;

$primary: #bb86fc;
$primaryTwo: #3700b3;
$secondary: #03dac6;
$error: #cf6679;
@keyframes backgroundImg {
  from {
    background-size: 150%;
  }
  to {
    background-size: cover;
  }
}

.menu {
  z-index: 6;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Quicksand", sans-serif;
  // for image effect
  background-repeat: no-repeat !important;
  transition: 1s ease-in-out;
  height: 100vh;
  width: 100vw;

  &.img-effect {
    // animation-name: backgroundImg;
    // animation-duration: 1s;
    background-size: cover !important;
  }
  ul {
    width: 100%;
    height: 50%;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    z-index: 3;
    li {
      margin-right: 70px;

      a {
        text-decoration: none;
        font-weight: 500;
        color: white;
        font-size: 4rem;
        text-shadow: 2px 3px #0d8fb7, 6px 7px #38318c, 8px 9px #bd1a87,
          10px 11px #e82323, 12px 13px #ef811b, 14px 15px #f5cc0d;
        transition: 0.5s;
        &:hover {
          position: relative;
          left: 4px;
          top: 4px;
          text-shadow: -2px 0 0.3px #0d8fb7, -6px 7px #38318c, -8px 9px #bd1a87,
            -10px 11px #e82323, -12px 13px #ef811b;
        }
      }
      .rounded-line {
        transition: 0.7s;
        width: 0;
        border-radius: 20px;
        background: white;
        display: block;
        height: 4px;
        box-shadow: -2px 0 0.3px #0d8fb7, -6px 7px #38318c, -8px 9px #bd1a87,
          -10px 11px #e82323, -12px 13px #ef811b;
      }
      &:hover {
        .rounded-line {
          width: 100%;
        }
      }
    }
  }
}

.footer {
  z-index: 6;

  position: fixed;
  bottom: 0;
  height: 100px;
  // margin: 50px 10px;
  border-top: 0.3px solid white;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .touch {
    background: none;
    color: white;
    font-size: 1.5em;
    border: 1px solid white;
    padding: 10px 20px;
    border-radius: 40px;
    transition: 0.5s;
    transition-timing-function: ease-in-out;
    &:hover {
      background-color: #8ec5fc;
      background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
      color: black;
    }
  }
  .links {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      color: white;
      font-size: 30px;
      margin-right: 20px;

      &:hover {
        text-shadow: 1px 2px $secondary;
      }
    }
  }
}
.instagram {
  display: inline-block;
  .outer {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    border: 2px solid white;
    border-radius: 7px;
    .inner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      border: 2px solid white;
      .dot {
        display: block;
        position: absolute;
        right: -6px;
        top: -7px;
        border: 3px solid white;
        width: 0px;
        height: 0px;
        border-radius: 50%;
        transform: scale(0.7);
      }
    }
  }
  &:hover {
    .outer,
    .inner {
      box-shadow: 1px 2px $error;
    }
  }
}
</style>
