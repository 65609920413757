<template>
  <div>
    <a-scene
      class="scene-0"
      embedded
      vr-mode-ui="enabled: false"
    >
      <!-- v-if="scrollAmount < elementSwitch" -->
      <a-entity
        camera
        look-controls="enabled: false"
        position="0 1.6 0"
      ></a-entity>

      <!-- <a-entity
        position="-5 0 0"
        animation="property: position; to: 5 1.25 -5; loop: false; dur: 1000; easing: linear"
      >
        <a-icosahedron
          color="white"
          radius="1"
          wireframe="true"
          animation="property: rotation; to: 0 360 0; dir: alternate; loop: true; dur: 20000; easing: easeInOutQuad"
        >
          <a-entity
            position="0 0 -0.5"
            line="start: 0 -2.8 0; end: 0 -1 0; color: red"
            animation__2="property: line.end; to: 0 -1.3 0; dir: alternate; loop: true; dur: 3000; easing: easeInOutElastic"
          ></a-entity>
        </a-icosahedron>
      </a-entity> -->
    </a-scene>
    <a-scene
      class="scene-1"
      embedded
      vr-mode-ui="enabled: false"
      v-if="!menuOpen"
    >
      <!-- v-else-if="scrollAmount > 500 && scrollAmount < 1600" -->
      <a-assets>
      </a-assets>
      <a-entity
        camera
        look-controls="enabled: false"
        position="0 1.6 0"
      ></a-entity>
      <a-entity>
        <a-gltf-model
          position="1 0 -12"
          src="models/earth.glb"
          scale="0 0 0"
          animation="property: scale; to: 0.08 0.08 0.08; loop: false; dur: 500; easing: easeInSine"
          animation__1="delay: 1000; property: position; to: 10 0 -12; dir: normal; loop: false; dur: 2000; easing: easeInOutQuad"
          animation__2=" property: rotation; to: 0 360 0; dir: normal; loop: true; dur: 20000; easing: easeInOutQuad"
          @animationcomplete="$emit('earthanimcomp')"
        ></a-gltf-model>
      </a-entity>
    </a-scene>

  </div>
</template>

<script>
export default {
  name: "Scene",
  props: ["menuOpen", "scrollAmount", "elementSwitch", "scene3CardsHover"],
  data: () => {
    return {};
  },
  mounted() {},
  methods: {
    cube: function (e) {
      console.log(document.querySelector(".animTest").object3D);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a-scene {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}
.scene-1 {
  top: 100vh;
}
.scene-2 {
  top: 200vh;
}
</style>
