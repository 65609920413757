<template>
  <div class="realization">
    <h2>REALIZATION IS US</h2>
    <h3>HYPER REALISTIC RENDERING</h3>
    <div
      class="heart"
      v-for="n in 10"
      :key="n"
      :style="{
        left: n + '0%',
        bottom: Math.random() * 20 + '%',
        animationDuration: Math.random() * 20 + 's',
      }"
    ></div>
    <img class="lions" src="img/main-page/cute-lions.webp" alt="" />
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "Realization",
  components: {},
  data: () => {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  font-size: 7rem;
  margin: 0;
}
h3 {
  font-size: 2rem;
  margin: 0;
}
.lions {
  width: 50%;
}

$heartW: 15px;
$heartH: $heartW;
.heart {
  position: absolute;
  width: $heartW;
  height: $heartH;
  background: red;
  border-radius: 50%;
  animation-name: heartAnim;
  animation-iteration-count: infinite;
  z-index: -1;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: $heartW;
    height: $heartH;
    background: red;
  }
  &::before {
    top: 40%;
    left: 40%;
    transform: rotate(45deg);
  }
  &::after {
    border-radius: 50%;
    left: 80%;
  }
}

@keyframes heartAnim {
  0% {
    transform: translateX(-40px);
  }
  50% {
    transform: translateX(40px);
  }
  100% {
    bottom: 100%;
    transform: translateX(-40px);
  }
}
</style>
