import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/digital-twin',
    name: 'DigitalTwin',
    component: () => import('../views/DigitalTwin.vue')
  },
  {
    path: '/xr',
    name: 'XR',
    component: () => import('../views/XR.vue')
  },
  {
    path: '/digital-world',
    name: 'DigitalWorld',
    component: () => import('../views/DigitalWorld.vue')
  },
  {
    path: '/animation',
    name: 'Animation',
    component: () => import('../views/Animation.vue')
  },
  {
    path: '/3d-scanning',
    name: 'Scan',
    component: () => import('../views/Scan.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
