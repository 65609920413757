<template>
  <div class="use-case">
    <img src="/img/main-page/ipad.webp" alt="" class="ipad-img" />
    <div class="right-wrap">
      <h3 class="text-wrap">
        XR tech is an upgrade for any use case and suitable for every domain.
        Use XR to teach and learn, fix and assemble, operate andinvestigate,
        train and maintain.
      </h3>
      <img src="/img/main-page/kids.webp" alt="" class="kids-img" />
    </div>
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "UseCase",
  components: {},
  data: () => {
    return {};
  },
  mounted() {},
  scrollTest: function () {
    console.log("work?");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
.use-case {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.text-wrap {
  font-family: "Patrick Hand", cursive;
  font-size: 3rem;
  max-width: 50%;
}
.ipad-img {
  transform: rotate(90deg) translateX(25%);
  width: 40%;
  height: 40%;
}
.right-wrap {
  width: 50%;
  display: flex;
  flex-direction: column;
  .kids-img {
    width: 50%;
  }
}
</style>
