<template>
  <div class="digitize">
    <div class="left-wrap">
      <div class="header-wrap">
        <h2>
          DIGITIZING <br />
          THE WORLD
        </h2>
        <img class="laptop" src="img/main-page/laptop-nodes.webp" alt="" />
      </div>
      <div class="bottom-wrap">
        <p>
          With the world getting bigger, globalization is always in a rush - we
          need to stick to the pace. 3D virtual technology is the most effecient
          and innovative way to upgrade the ecommerce experience, with the
          ability to see everything better, closer and more tangible.
        </p>
      </div>
    </div>
    <div class="neck-twister">
      <p>
        E-COMMERCE PRODUCTS <br />
        AND SOLUTIONS
      </p>
    </div>
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "Digitize",
  components: {},
  data: () => {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.digitize {
  font-family: "Patrick Hand", cursive;
  margin: 0 70px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  .left-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header-wrap {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 4rem;
      }
      .laptop {
        width: 20%;
      }
    }
    .bottom-wrap {
      padding: 30px;
      font-size: 2.5rem;
    }
  }
}

.neck-twister {
  font-size: 2.5rem;
  position: relative;
  width: 20%;
  height: 100%;
  p {
    position: absolute;
    bottom: 50%;
    left: -50%;
    width: 500px;
    margin: 0;
    padding: 0;
    transform: rotate(90deg);
    line-break: auto;
  }
}
</style>
