<template>
  <div :class="`nav ${menuOpen ? 'full-screen' : ''}`">
    <router-link
      to="/"
      class="logo"
      @mouseenter="cursorSize('enter')"
      @mouseleave="cursorSize('leave')"
    >
      <span :class="`square ${mouseHover ? 'hovering' : ''}`"></span
      ><span class="innerTxt">3D<span class="subInnerTxt">IN</span></span>
      <span class="mainTxt">Simply Expand</span>
    </router-link>
    <div
      :class="`menuBtn ${menuOpen ? 'active' : ''}`"
      title="menu"
      @click="menuOpen = !menuOpen"
      @mouseenter="cursorSize('enter')"
      @mouseleave="cursorSize('leave')"
    >
      <div class="pix" v-for="n in 9" :key="n"></div>
    </div>

    <Menu v-if="menuOpen" :mouseHover="mouseHover" />
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";

export default {
  name: "Nav",
  props: [""],
  components: { Menu },
  data: () => {
    return { menuOpen: false };
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$pix-size: 8px;
.nav {
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
}
.menuBtn {
  position: fixed;
  top: 40px;
  right: 50px;
  display: block;
  width: ($pix-size * 5);
  height: ($pix-size * 5);
  margin: 0;
  padding: 20px;
  z-index: 56;

  .pix {
    position: absolute;
    width: $pix-size;
    height: $pix-size;
    background-color: white;
    border-radius: 100%;
    transition: 0.2s ease-in-out;

    $num: 0;
    @for $i from 1 through 9 {
      @if $i%3 == 1 {
        $num: 0;
      }

      &:nth-of-type(#{$i}) {
        transform: translate(
          ($pix-size * 2) * $num,
          ($pix-size * 2) * (floor(($i - 1)/3))
        );
      }

      $num: $num + 1;
    }
  }

  &:hover {
    .pix {
      background-color: white;

      &:nth-of-type(1) {
        transform: translate(-($pix-size), -($pix-size));
      }
      &:nth-of-type(3) {
        transform: translate(($pix-size * 5), -($pix-size));
      }
      &:nth-of-type(7) {
        transform: translate(-($pix-size), ($pix-size * 5));
      }
      &:nth-of-type(9) {
        transform: translate(($pix-size * 5), ($pix-size * 5));
      }
    }
  }

  &.active {
    .pix {
      &:nth-of-type(1) {
        transform: translate(0, 0);
      }
      &:nth-of-type(2) {
        transform: translate($pix-size, $pix-size);
      }
      &:nth-of-type(3) {
        transform: translate(($pix-size * 4), 0);
      }
      &:nth-of-type(4) {
        transform: translate($pix-size, ($pix-size * 3));
      }
      &:nth-of-type(6) {
        transform: translate(($pix-size * 3), $pix-size);
      }
      &:nth-of-type(7) {
        transform: translate(0, ($pix-size * 4));
      }
      &:nth-of-type(8) {
        transform: translate(($pix-size * 3), ($pix-size * 3));
      }
      &:nth-of-type(9) {
        transform: translate(($pix-size * 4), ($pix-size * 4));
      }
    }

    &:hover {
      .pix {
        &:nth-of-type(1) {
          transform: translate(-($pix-size), -($pix-size));
        }
        &:nth-of-type(2) {
          transform: translate(($pix-size/2), ($pix-size/2));
        }
        &:nth-of-type(3) {
          transform: translate(($pix-size * 5), -($pix-size));
        }
        &:nth-of-type(4) {
          transform: translate(($pix-size/2), ($pix-size * 3.5));
        }
        &:nth-of-type(6) {
          transform: translate(($pix-size * 3.5), ($pix-size/2));
        }
        &:nth-of-type(7) {
          transform: translate(-($pix-size), ($pix-size * 5));
        }
        &:nth-of-type(8) {
          transform: translate(($pix-size * 3.5), ($pix-size * 3.5));
        }
        &:nth-of-type(9) {
          transform: translate(($pix-size * 5), ($pix-size * 5));
        }
      }
    }
  }
}
</style>
