<template>
  <div class="taylor-made">
    <div class="text-wrap">
      <h2 class="taylor">Taylor made 3D software</h2>
      <h2 class="made">CUSTOMIZED HARDWARE</h2>
    </div>
    <div class="img-wrap">
      <img src="/img/main-page/trimble.png" alt="" />
      <div class="we-wrap">
        <h3>WE</h3>
        <p>
          are experienced with all xr platforms faced numerous difficult cases
          IN THE PAST made it through walls that seemed too high KNOW HOW TO USE
          OUR TOOLS AND GET THE JOB DONE!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "TaylorMade",
  components: {},
  data: () => {
    return {};
  },
  mounted() {},
  scrollTest: function () {
    console.log("work?");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.taylor-made {
  font-family: "Patrick Hand", cursive;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  position: relative;
  .text-wrap {
    transform: rotate(90deg);
    font-size: 3rem;
      background-image: linear-gradient(to top, #f6d365 0%, #fda085 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    h2 {
      margin: 0;
      // background-image: linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);
    }
    .taylor {
      color: #fcbd01;
    }
    .made {
      color: #fffa02;
    }
  }
  .img-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 400px;
    }
    // align-items: center;
    .we-wrap {
      display: flex;
      align-items: center;
      h3 {
        margin: 0;
        font-size: 150px;
        margin-right: 10px;
        color: #fcbd01;
        background-image: linear-gradient(to top, #f6d365 0%, #fda085 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        // font-weight: 300;
        text-transform: uppercase;

        width: 100%;
        max-width: 520px;
        text-align: left;
        font-size: 23px;
      }
    }
  }
}
</style>
