<template>
  <div class="home">
    <HomeMain msg="Welcome to Your Vue.js App"/>
    <Nav />

  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import HomeMain from '@/components/HomeMain.vue'

export default {
  name: 'Home',
  components: {
    HomeMain,Nav
  }
}
</script>
