<template>
  <div class="extended-reality">
    <h2 class="main-text">Extended Reality</h2>
    <div class="holo-wrap">
      <img class="holo" src="/img/main-page/holo.png" alt="" />
    </div>
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "ExtendedReality",
  components: {},
  data: () => {
    return {
      typedText: "",
      moveProgress: 0,
    };
  },
  mounted() {},
  methods: {
    startTyped: function () {
      // var options1 = {
      //   strings: ["Extended Reality"],
      //   typeSpeed: 200,
      // };
      // var typed = new Typed(".main-text", options1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$black: #0a0a0a;
$gray: #212121;
$lightGray: #bfbfbf;
$background: #1f1b24;

$primary: #bb86fc;
$primaryTwo: #3700b3;
$secondary: #03dac6;
$error: #cf6679;

.extended-reality {
  width: 100%;
  height: 100%;
  position: relative;
  .main-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7rem;
    display: inline;
    color: #30cfd0;
    transition: 1s;

    // background: linear-gradient(to right, #330867 0%, #30cfd0 95%);
    background-image: linear-gradient(
      to top,
      #3f51b1 0%,
      #5a55ae 13%,
      #7b5fac 25%,
      #8f6aae 38%,
      #a86aa4 50%,
      #cc6b8e 62%,
      #f18271 75%,
      #f3a469 87%,
      #f7c978 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:before {
      content: "";
      position: absolute;
      background: #f7c978;
      top: 0;
      right: 0;
      width: 100%;
      height: 3px;
      transition: 1s;
    }
    &:after {
      content: "";
      position: absolute;
      background: #a86aa4;
      bottom: 0;
      left: 0;
      width: 30%;
      height: 3px;
      transition: 1s;
    }
  }
    .holo-wrap {
    perspective: 1000px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .holo {
      transition: 1s;
    }
  }
  &:hover {
    .main-text {
      transform: translate(-70%, -50%);
      &:before {
        width: 30%;
        box-shadow: 10px 0px 12px #f7c978;
      }
      &:after {
        width: 100%;
        box-shadow: 10px 0px 12px #a86aa4;
      }
    }
    .holo {
      transform: scale(0.25) rotateY(-20deg) translateX(100%);
    }
  }

}
</style>
