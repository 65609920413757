<template>
  <div class="animations">
    <h2 class="header">WE MAKE 3D ANIMATIONS!</h2>
    <p>
      If it is for marketing, instructing, gaming or for any other production,
      3D animation rendering is a magical tool to share with the world your
      thoughts, beliefs and imagaination. We can talk about 3D animation for
      days bot its better if you just see it for yourself
    </p>

    <div class="laptop">
      <div class="mockup mockup-macbook loaded opened">
        <div class="part top">
          <div class="screen-panel-wrap">
            <div class="panel">
              <iframe
                src="https://www.youtube.com/embed/0-3SlBIgjPA?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="rounded-area"></div>
          <div class="indent"></div>
        </div>
      </div>
    </div>
    <div class="brushes">
      <div :class="`brush b` + n" v-for="n in 2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="44px"
          viewBox="0 0 24 24"
          width="44px"
          :fill="n === 1 ? '#ffff00' : '#ff0000'"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M7 16c.55 0 1 .45 1 1 0 1.1-.9 2-2 2-.17 0-.33-.02-.5-.05.31-.55.5-1.21.5-1.95 0-.55.45-1 1-1M18.67 3c-.26 0-.51.1-.71.29L9 12.25 11.75 15l8.96-8.96c.39-.39.39-1.02 0-1.41l-1.34-1.34c-.2-.2-.45-.29-.7-.29zM7 14c-1.66 0-3 1.34-3 3 0 1.31-1.16 2-2 2 .92 1.22 2.49 2 4 2 2.21 0 4-1.79 4-4 0-1.66-1.34-3-3-3z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
// import Typed from 'typed.js';

export default {
  name: "Animations",
  components: {},
  data: () => {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.animations {
  padding: 50px;
}
p {
  font-size: 2rem;
}
.header {
  font-size: 4rem;
}

.brushes {
  position: absolute;
  height: 20%;
  width: 10%;
  bottom: 5%;
  left: 7%;
  .brush {
    position: absolute;
    width: 42px;
    height: 42px;
    bottom: 0;
    animation-iteration-count: infinite;
    animation-duration: 3s;
  }
  .b1 {
    animation-name: b1;
    right: 100%;
  }
  .b2 {
    animation-name: b2;
    right: 0;
  }
}
@keyframes b1 {
  0% {
  }
  50% {
    right: 0;
  }
  100% {
    right: 100%;
    bottom: 100%;
  }
}
@keyframes b2 {
  0% {
  }
  50% {
    right: 100%;
  }
  100% {
    right: 0;
    bottom: 100%;
  }
}
</style>
